const impressionIds = new Array<string>();

// For tracking which sponsored product that client has viewed while they are in PDV, we will send the the ads id to GFG
export const trackingImpression = (adsId?: string) => {
  const citrusAd = window.citrusAd;

  if (!citrusAd || !adsId || impressionIds.includes(adsId)) {
    return;
  }

  try {
    impressionIds.push(adsId);
    citrusAd.reportImpression && citrusAd.reportImpression(adsId);
  } catch (_) {
    return;
  }
};

// For tracking which sponsored product that customers click on it
export const trackingClick = (adsId?: string) => {
  const citrusAd = window.citrusAd;

  if (!citrusAd || !adsId) {
    return;
  }

  try {
    citrusAd.reportClick && citrusAd.reportClick(adsId);
  } catch (_) {
    return;
  }
};
