import { ZDTProduct } from '@zalora/doraemon-ts';
import { getProductScarcityCounts } from 'api/products';
import {
  PrioritySocialProofingMetricSet,
  PrioritySocialProofingMetricSetKey,
  SocialProofingMetric,
  SocialProofingMetricItem,
  SocialProofingTrackingDataSet,
  SocialProofingTrackingInfo,
} from 'types/SocialProofing';
import { getFirstAsArray } from './array';
import { isObject } from './validation';

export const isScarcityMapData = (obj: unknown, sku: string): obj is ZDTProduct.ScarcityMap => {
  return isObject(obj) && isObject(obj[sku]);
};

export const getOrderedScarcityData = (data: ZDTProduct.ScarcityCount) => {
  // ZDTProduct.ScarcityCount type is not easily usable for processing. Thus, we make one.
  const refinedScarcitySet: SocialProofingMetricItem[] = [
    {
      count: data.CountInBag || 0,
      label: SocialProofingMetric.CountInBag,
      isThresholdPassed: data.IsCountInBagThresholdPassed || false,
    },
    {
      count: data.CountInWishlist || 0,
      label: SocialProofingMetric.CountInWishlist,
      isThresholdPassed: data.IsCountInWishlistThresholdPassed || false,
    },
    {
      count: data.CountInSales || 0,
      label: SocialProofingMetric.CountInSales,
      duration: data.CountInSalesDays || 0,
      isThresholdPassed: data.IsCountInSalesThresholdPassed || false,
    },
    {
      count: data.CountInViews || 0,
      label: SocialProofingMetric.CountInViews,
      duration: data.CountInViewsDays || 0,
      isThresholdPassed: data.IsCountInViewsThresholdPassed || false,
    },
  ];

  const sortedScarcitySet = sortScarcityByPriority(refinedScarcitySet);

  if (sortedScarcitySet.length === 0) {
    return undefined;
  }

  // Only show the top 2 social proofing message to reduce mental load.
  return sortedScarcitySet.slice(0, 2);
};

/**
 * Sorts the different types of scarcity counts based on priority.
 *
 * Priority order:
 *  - Purchases - Priority 1
 *  - Views - Priority 2
 *  - Count In Bag  - Priority 3
 *  - Count in Wishlist - Priority 4
 *
 * At least returns one type of scarcity count
 */
export const sortScarcityByPriority = (scarcitySet: SocialProofingMetricItem[]) => {
  return scarcitySet.reduce((scarcityCollection, scarcityItem: SocialProofingMetricItem) => {
    const { label, count, isThresholdPassed } = scarcityItem;

    if (!label || !count || !isThresholdPassed) {
      return scarcityCollection;
    }

    // Sorting mechanism ✨

    if (scarcityCollection.length === 0) {
      scarcityCollection.push(scarcityItem);

      return scarcityCollection;
    }

    const { label: prevLabel } = scarcityCollection[0];

    const prevScarcityPriority =
      PrioritySocialProofingMetricSet[prevLabel as PrioritySocialProofingMetricSetKey];
    const scarcityPriority =
      PrioritySocialProofingMetricSet[label as PrioritySocialProofingMetricSetKey];

    if (prevScarcityPriority > scarcityPriority) {
      scarcityCollection.unshift(scarcityItem);
    } else if (prevScarcityPriority < scarcityPriority) {
      scarcityCollection.splice(1, 0, scarcityItem);

      return scarcityCollection;
    }

    return scarcityCollection;
  }, [] as SocialProofingMetricItem[]);
};

export const getCatalogSocialProofingDataBySku = (
  configSku: string,
  socialProofingData?: Record<string, SocialProofingMetricItem[]>,
) => {
  if (!configSku || !socialProofingData) {
    return [];
  }

  const productSocialProofingData = socialProofingData[configSku] || [];

  return productSocialProofingData;
};

export const getCatalogProductsScarcityData = async (configSkus: string[]) => {
  if (configSkus.length === 0) {
    return {};
  }

  // Using ConfigSku to maintain consistency across various platforms
  const productScarcityData = await getProductScarcityCounts({ params: { configSku: configSkus } });

  if (!productScarcityData || !productScarcityData.Scarcities) {
    return {};
  }

  const initialScarcityData: Record<string, SocialProofingMetricItem[]> = {};

  return Object.entries(productScarcityData.Scarcities).reduce((prev, data) => {
    const [sku, scarcityData] = data || [];

    if (!sku || !scarcityData) {
      return prev;
    }

    const skuScarcityData = getOrderedScarcityData(scarcityData);

    // Only use the top most social proofing message for catalog pages.
    const topScarcityData = getFirstAsArray(skuScarcityData || []);

    if (skuScarcityData) {
      return { ...prev, [sku]: topScarcityData };
    }

    return prev;
  }, initialScarcityData);
};

export const getSocialProofingTrackingProperties = (data: SocialProofingMetricItem[]) => {
  const initialTrackingInfo: SocialProofingTrackingInfo = {
    sp_views_displayed: false,
    sp_purchases_displayed: false,
    sp_bag_count_displayed: false,
    sp_wishlist_count_displayed: false,
  };

  return data.reduce((acc, item) => {
    const { label, isThresholdPassed } = item;
    const key = SocialProofingTrackingDataSet[label as keyof typeof SocialProofingMetric];

    if (key) {
      return { ...acc, [key]: isThresholdPassed };
    }

    return acc;
  }, initialTrackingInfo);
};

export const getScarcityDataBySku = (sku: string, scarcityCountSet?: ZDTProduct.ScarcityMap) => {
  const { Scarcities: scarcityCountsData } = scarcityCountSet || {};

  if (!sku || !isScarcityMapData(scarcityCountsData, sku)) {
    return undefined;
  }

  const scarcityDataBySku = scarcityCountsData[sku];
  const scarcityData = getOrderedScarcityData(scarcityDataBySku);

  return scarcityData;
};
