import { ZDTSEO } from '@zalora/doraemon-ts';
import { SOURCE_CATALOG_DATA } from 'constants/tracking';
import { isEmptyObject } from 'utils/validation';
import { sortCategoryByDepth } from './breadcrumbs';

/**
 * CAUTION: This is used for catalog page tracking only
 * @param seoInfo
 * @returns [sourceCatalog, listId]
 */
export const getSourceCatalogListId = (
  seoInfo: ZDTSEO.CatalogInfo | undefined,
): [string, string] => {
  if (seoInfo && !isEmptyObject(seoInfo)) {
    // Brands
    if (!!seoInfo?.PageInfo?.Brand?.Id) {
      const { Brand } = seoInfo.PageInfo;

      return [SOURCE_CATALOG_DATA.Brand, Brand?.Name || ''];
    }

    // Category
    if (seoInfo?.PageInfo?.CategoryBreadcrumbs?.length) {
      const { CategoryBreadcrumbs } = seoInfo.PageInfo;
      const sortedBreadcrumbs = sortCategoryByDepth(CategoryBreadcrumbs);

      return [
        SOURCE_CATALOG_DATA.Category,
        sortedBreadcrumbs[sortedBreadcrumbs.length - 1]?.Name || '',
      ];
    }
  }

  // Search
  const queryParams = new URLSearchParams(window.location.search);
  const searchString = queryParams && queryParams.get('q');

  if (searchString) {
    return [SOURCE_CATALOG_DATA.Search, searchString];
  }

  // Campaign
  const campageKeys = ['cmpgnOne', 'cmpgnTwo', 'cmpgnThree', 'cmpgnFour', 'cmpgnFive', 'cmpgnSix'];

  for (const key of campageKeys) {
    const cmpgnString = queryParams && queryParams.get(key);

    if (cmpgnString) {
      return [SOURCE_CATALOG_DATA.Campaign, cmpgnString];
    }
  }

  // default case if seoInfo is null
  return ['', ''];
};
