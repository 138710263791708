export const enum SocialProofingMetric {
  CountInSales = 'CountInSales',
  CountInBag = 'CountInBag',
  CountInWishlist = 'CountInWishlist',
  CountInViews = 'CountInViews',
}

export const PrioritySocialProofingMetricSet = {
  [SocialProofingMetric.CountInSales]: 1,
  [SocialProofingMetric.CountInViews]: 2,
  [SocialProofingMetric.CountInBag]: 3,
  [SocialProofingMetric.CountInWishlist]: 4,
} as const;

export type PrioritySocialProofingMetricSetKey = keyof typeof PrioritySocialProofingMetricSet;

export interface SocialProofingMetricItem {
  label: string;
  count: number;
  duration?: number;
  isThresholdPassed: boolean;
}

const enum SocialProofingTrackingProperty {
  SpPurchasesDisplayed = 'sp_purchases_displayed',
  SpViewsDisplayed = 'sp_views_displayed',
  SpBagCountDisplayed = 'sp_bag_count_displayed',
  SpWishlistCountDisplayed = 'sp_wishlist_count_displayed',
}

export interface SocialProofingTrackingInfo {
  [SocialProofingTrackingProperty.SpPurchasesDisplayed]: boolean;
  [SocialProofingTrackingProperty.SpViewsDisplayed]: boolean;
  [SocialProofingTrackingProperty.SpBagCountDisplayed]: boolean;
  [SocialProofingTrackingProperty.SpWishlistCountDisplayed]: boolean;
}

export const SocialProofingTrackingDataSet = {
  [SocialProofingMetric.CountInSales]: SocialProofingTrackingProperty.SpPurchasesDisplayed,
  [SocialProofingMetric.CountInViews]: SocialProofingTrackingProperty.SpViewsDisplayed,
  [SocialProofingMetric.CountInBag]: SocialProofingTrackingProperty.SpBagCountDisplayed,
  [SocialProofingMetric.CountInWishlist]: SocialProofingTrackingProperty.SpWishlistCountDisplayed,
} as const;
