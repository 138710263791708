import {
  RecFeedLayout,
  REC_FEED_PRODUCT_ADDED_TO_WISHLIST,
  REC_FEED_PRODUCT_CLICKED,
  REC_FEED_PRODUCT_CLICKED_ITEM_LEVEL,
  REC_FEED_PRODUCT_VIEWED,
  REC_FEED_PRODUCT_VIEWED_ITEM_LEVEL,
  SegmentEvent,
} from 'constants/tracking';

export const getRecFeedEventName = (event: SegmentEvent, isItemLevel?: boolean) => {
  switch (event) {
    case SegmentEvent.IMPRESSION: {
      return isItemLevel ? REC_FEED_PRODUCT_VIEWED_ITEM_LEVEL : REC_FEED_PRODUCT_VIEWED;
    }

    case SegmentEvent.CLICK: {
      return isItemLevel ? REC_FEED_PRODUCT_CLICKED_ITEM_LEVEL : REC_FEED_PRODUCT_CLICKED;
    }

    case SegmentEvent.ADD_TO_WISHLIST: {
      return REC_FEED_PRODUCT_ADDED_TO_WISHLIST;
    }
  }
};

export const getRecFeedLayoutType = (layoutType?: string) => {
  switch (layoutType) {
    case 'grid': {
      return RecFeedLayout.GRID;
    }

    default: {
      return RecFeedLayout.ROW;
    }
  }
};
