import { getSponsoredAdsRecFeedItemRow, getNumRecFeedItemPerRow } from 'constants/recommendations';
import { TRACKING } from 'constants/tracking';
import { ExtendedZDTProductWithSkuPosition, LotusProduct } from 'types/LDT';
import { ZDTInteractiveStore } from 'types/ZDT';
import { SOURCE_PAGE_TYPE } from 'utils/page-type';
import * as productUtils from 'utils/product';
import { track } from 'utils/segment-analytic';

export type ViewedProduct = {
  index?: number;
} & LotusProduct;

const viewedProducts: LotusProduct[] = [];
const VIEWED_PRODUCT_DEBOUNCE_TIME = 500;
let viewedProductTimerId: Nullable<number> = null;

const SUB_CATEGORY_LIST_CLASS_WRAPPER = 'sa-tracking-categories-list';

export const EVENT_NAMES = {
  CATEGORY_NAV_CLICKED: 'Category Nav Clicked',
  BRAND_NAV_CLICKED: 'Brand Nav Clicked',
  SHOP_BY_RANGE_NAV_CLICKED: 'Shop By Range Nav Clicked',
} as const;

export const enum CategoryLevel {
  ROOT = 1,
  SUB = 2,
  SUBSUB = 3,
}

export const getCurrentSegment = () => window.trackingData?.activeSegment || '';

export const trackingOnClickRootCategory = (cateName: string, position: number) => {
  const payload = {
    category_level: CategoryLevel.ROOT,
    category_name: cateName,
    position,
    x_position: position, // category is horizontal only
    y_position: '',
    source_segment: getCurrentSegment(),
  };

  track(EVENT_NAMES.CATEGORY_NAV_CLICKED, payload);
};

export const trackingOnShopByRangeNavClicked = (categoryName: string, bannerName: string) => {
  const payload = {
    category_name: categoryName,
    shop_by_range_name: bannerName,
    source_segment: getCurrentSegment(),
  };

  track(EVENT_NAMES.SHOP_BY_RANGE_NAV_CLICKED, payload);
};

export const trackingOnClickSubCategory = (
  cateName: string,
  yPosition: number,
  xPosition?: number,
) => {
  const payload = {
    category_level: CategoryLevel.SUB,
    category_name: cateName,
    yPosition,
    x_position: xPosition || '', // row position of category inside menu
    y_position: yPosition,
    source_segment: getCurrentSegment(),
  };

  track(EVENT_NAMES.CATEGORY_NAV_CLICKED, payload);
};

export const trackingOnClickCategory = (
  catName: string,
  catLevel: CategoryLevel,
  yPosition: number,
  xPosition?: number,
) => {
  const payload = {
    category_level: catLevel,
    category_name: catName,
    yPosition,
    x_position: xPosition || '', // row position of category inside menu
    y_position: yPosition,
    source_segment: getCurrentSegment(),
  };

  track(EVENT_NAMES.CATEGORY_NAV_CLICKED, payload);
};

const getElementPosition = (element: HTMLElement, selector: string) => {
  const parent = element.parentElement;

  if (!parent) {
    return 0;
  }

  const children = parent.querySelectorAll(selector);

  if (!children) {
    return 0;
  }

  return Array.from(children).indexOf(element);
};

export const onClickSubCategory = (element: HTMLElement) => {
  const subCategoryLinkEl = element.closest('a[href]');

  if (!subCategoryLinkEl) {
    return;
  }

  // Find row index - y_position
  const categoryName = subCategoryLinkEl.textContent?.trim();
  const categoryPosition = getElementPosition(
    subCategoryLinkEl as HTMLElement,
    ':scope > a[href]', // avoid a divisor line
  );

  // Find column index - x_position
  const closetCategoryParent = element.closest(`.${SUB_CATEGORY_LIST_CLASS_WRAPPER}`);
  const closetCategoryParentPosition = getElementPosition(
    closetCategoryParent as HTMLElement,
    `:scope > .${SUB_CATEGORY_LIST_CLASS_WRAPPER}`,
  );

  trackingOnClickSubCategory(categoryName || '', categoryPosition, closetCategoryParentPosition);
};

export const getRecFeedListId = (
  sourcePageType: SOURCE_PAGE_TYPE | undefined,
  {
    product,
    strategyMessage,
    storeInfo,
  }: {
    product?: LotusProduct;
    strategyMessage?: string;
    storeInfo?: ZDTInteractiveStore.Store;
  },
): string => {
  switch (sourcePageType) {
    case SOURCE_PAGE_TYPE.Homepage: {
      return strategyMessage || ''; // strategyMessage of RR
    }

    case SOURCE_PAGE_TYPE.Catalog: {
      return ''; // Seem there is no RecFeed on Catalog page - need to checl again
    }

    case SOURCE_PAGE_TYPE.ProductDetails: {
      return product ? productUtils.getName(product) : '';
    }

    case SOURCE_PAGE_TYPE.Cart: {
      return TRACKING.LIST_IDS.CART;
    }

    case SOURCE_PAGE_TYPE.Wishlist: {
      return TRACKING.LIST_IDS.WISHLIST;
    }

    case SOURCE_PAGE_TYPE.ZIS: {
      const productSellerName = product ? productUtils.getSellerName(product) : '';

      if (productSellerName) {
        return productSellerName;
      }

      return storeInfo?.SellerName || '';
    }

    default: {
      return '';
    }
  }
};

// Get position of Sponsored Product
export const getSponsoredProductPosition = (index: number) => {
  const productsPerRow = getSponsoredAdsRecFeedItemRow(); // The number of products to display per row in the Sponsored Ads Product

  return {
    y_position: String(productsPerRow ? Math.floor(index / productsPerRow) + 1 : 1),
    x_position: String(index + 1),
  };
};

// Get position of RecFeed Product
export const getRecFeedProductPosition = (index: number) => {
  const productsPerRow = getNumRecFeedItemPerRow(); // The number of products to display per row in the recommendations feed

  return {
    y_position: String(productsPerRow ? Math.floor(index / productsPerRow) + 1 : 1),
    x_position: String(index + 1),
  };
};

/**
 * Add newly viewed products to tracking list
 */
export const trackViewedProducts = ({
  viewedProduct,
  skuPosition,
  callback,
}: {
  viewedProduct: LotusProduct;
  skuPosition: number;
  callback: (viewedProducts: ViewedProduct[]) => void;
}) => {
  const isTracked = !!viewedProducts.find(
    (untrackedProduct) =>
      productUtils.getConfigSku(untrackedProduct) === productUtils.getConfigSku(viewedProduct),
  );

  if (!isTracked && viewedProduct) {
    const productWithPosition: ExtendedZDTProductWithSkuPosition = {
      ...viewedProduct,
      skuPosition,
    };

    viewedProducts.push(productWithPosition);
  }

  if (viewedProducts.length !== 0) {
    sendViewedProductTrackingEvent(callback);
  }
};

/**
 * Send tracking event for viewed products
 */
const sendViewedProductTrackingEvent = (
  callback: (viewedProducts: ExtendedZDTProductWithSkuPosition[]) => void,
) => {
  if (viewedProductTimerId) {
    clearTimeout(viewedProductTimerId);
  }

  viewedProductTimerId = window.setTimeout(() => {
    callback(viewedProducts);

    viewedProducts.length = 0;
  }, VIEWED_PRODUCT_DEBOUNCE_TIME);
};
