import { ZDTCatalog, ZDTProduct, ZDTRatingsReviews } from '@zalora/doraemon-ts';
import apiClient, { APIRequestConfig, simpleFetch } from 'api/APIClient';
import { captureError } from 'utils/raven';
import { getCleanedProductSlugOrSku } from 'utils/url';

export const enum ProductApis {
  details = 'details',
  availability = 'availability',
  care = 'care',
  size = 'size',
  reviews = 'reviews',
  reviewsStatistics = 'reviews/statistics',
  sizeindicator = 'sizeindicator',
  specs = 'specs',
  scarcity = 'scarcity',
}

export const getProductDetails = async <T>(
  api: ProductApis,
  skuOrSlug: string,
  config?: APIRequestConfig,
) => {
  const cleanedSkuOrSlug = getCleanedProductSlugOrSku(skuOrSlug);
  const path = `/v1/products/${cleanedSkuOrSlug}/${api}`;

  return simpleFetch<T>(path, config);
};

export const getProductReviewStatistics = async (skuOrSlug: string) => {
  const cleanedSkuOrSlug = getCleanedProductSlugOrSku(skuOrSlug);

  return simpleFetch<ZDTRatingsReviews.ProductReviewStatistics>(
    `/v1/products/${cleanedSkuOrSlug}/reviews/statistics`,
  );
};

export const getProductReviews = async (slugOrSku: string, token = '', perPage = 2) => {
  const cleanedSlug = getCleanedProductSlugOrSku(slugOrSku);

  return simpleFetch<ZDTRatingsReviews.ProductReviewList>(`/v1/products/${cleanedSlug}/reviews`, {
    params: { per_page: perPage, next: token },
  });
};

export const getProductReviewTranslation = async (reviewId: number, commentId: number) => {
  return simpleFetch<ZDTRatingsReviews.CommentTranslation>(
    `/v1/reviews/${reviewId}/comments/${commentId}/translation`,
  );
};

export const stockReminder = async ({
  email,
  simpleSku,
  gender,
  newsletterSubscription,
}: {
  email: string;
  simpleSku: string;
  gender?: string;
  newsletterSubscription?: boolean;
}) => {
  try {
    const body = new FormData();

    body.append('email', email);
    body.append('simpleSku', simpleSku);

    if (gender) {
      body.append('gender', gender);
    }

    if (newsletterSubscription) {
      body.append('newsletterSubscription', newsletterSubscription.toString());
    }

    const response = await apiClient.post('/v1/products/stock-reminder', { body });

    if (response.ok) {
      return true;
    }
  } catch (error) {
    captureError('Error when subscribe stock reminder', {
      error,
      tag: 'submit-request',
      params: { simpleSku },
    });
  }

  return false;
};

export const getProductIndexes = async () => {
  try {
    const response = await simpleFetch<ZDTProduct.Indexes>('/v1/products/indexes');

    const { data: { Indexes = [] } = {} } = response;

    return Indexes;
  } catch (error) {
    captureError('Cannot get product indexes list', { error, tag: 'get-request' });

    return [];
  }
};

export const getProductScarcityCounts = async (config: APIRequestConfig) => {
  const { status, data, error } = await simpleFetch<ZDTProduct.ScarcityMap>(
    '/v1/products/scarcity',
    config,
  );

  if (status === 500) {
    captureError('Cannot get product scarcity counts', {
      error,
      tag: 'get-request',
      params: config.params,
    });

    return undefined;
  }

  return data;
};

export const getProductSegmentTrackingData = async (sku: string) => {
  return simpleFetch<ZDTCatalog.ProductSegmentTrackings>(`/v1/products/segmenttracking/${sku}`);
};
